@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.AppointmentDetails {
  &-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-headerCardHr {
    border: 1px solid transparent;
    background-color: $BorderGray;
    margin-top: 1.5rem;
    width: 100%;
  }

  &-headerCardDescription {
    font-size: 13px;
    font-style: italic;
    color: $TextDarkerGray;

    a {
      font-style: inherit;
      color: $MainCyan;
    }
  }

  &-map {
    padding-bottom: 16px;
  }

  &-cta {
    &-toolbar {
      height: 50px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      button,
      a {
        flex: 1;
        height: 50%;
      }
      &-add-to-calendar {
        // margin-bottom: 0.7rem;
      }
    }

    &-maps {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: 100px;

      button {
        flex: 1;
        height: 50%;
        max-height: 50px;

        &:first-of-type {
          margin-bottom: 0.7rem;
        }
      }

      &-apple {
        flex: 1;
        background-color: $black;
        border: 1px solid $BorderGray;
        box-shadow: $GenericBoxShadow;
        margin-bottom: 0.7rem;

        img {
          position: relative;
          bottom: 2px;
          max-width: 20px;
        }

        &:active {
          background-color: $black;
        }
      }

      &-google {
        flex: 1;
        background-color: $white;
        color: $GoogleFontColor;
        border: 1px solid $BorderGray;
        box-shadow: $GenericBoxShadow;

        &:active {
          background-color: $white;
        }
      }
    }
  }
}

@include desktopAndTablet {
  .AppointmentDetails {
    &-cta-maps {
      flex-direction: row;

      .cta-button {
        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }
  }
}
