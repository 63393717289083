@import '../../styles/AppDesign.scss';

$cardPadding: 16;
$cardPaddingPx: 16px;

.Card {
  flex: 1;
  background-color: $white;
  border-radius: 10px;
  padding: $cardPaddingPx;
  box-shadow: $GenericBoxShadow;
  margin: #{$cardPadding / 2}px 0px;
}

.CardsRow {
  display: flex;
  flex-direction: row;

  & > .Card {
    margin-left: #{$cardPadding / 2}px;
    margin-right: #{$cardPadding / 2}px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
