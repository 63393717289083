@import '../../../../styles/AppDesign.scss';

.FormPdfPreview {
  width: 100%;
  height: 100%;

  &-in-review {
    color: $CoralRed;
    font-size: 14px;
    padding-bottom: 20px;
  }

  &-document {
    width: 100%;

    &-loading-skeleton {
      height: 80vh;
      min-height: 600px;
      --base-color: #e6eaed;
    }

    &.mod-in-review {
      filter: brightness(0.92);
    }
  }
}
