@import '../../styles/AppDesign.scss';

.FormDetails {
  &-loading {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main-layout {
    min-height: calc(100vh - 80px);
    box-sizing: border-box;
  }

  &-preview {
    padding-top: 0px;
    padding-bottom: 20px;
    height: 100%;
  }

  &-unsupported {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $TextGray;
    min-height: 200px;
  }
}
