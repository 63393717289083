@keyframes blurIn {
  0% {
    filter: blur(0);
  }
  100% {
    filter: blur(5px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
