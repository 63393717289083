@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.OTPVerifyLayout {
  &-timer {
    text-align: center;
    font-size: 16px;
    color: $MainCyan;
    margin-top: 14px;
    margin-bottom: 0px;

    &.mod-zero {
      visibility: hidden;
    }
  }

  &-form {
    margin-top: 40px;

    &-error {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}

@include desktop {
  .OTPVerifyLayout {
    .VisitorLayout {
      &-container {
        height: 100%;
      }
    }
  }
}
