@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';
@import '../../styles/animations.scss';

.MainLayout {
  width: 100%;
  margin: 0 auto;
  padding: $AppPaddingPx;
  box-sizing: border-box;

  &-wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: scroll;
  }

  &-main {
    flex: 1;
    overflow-y: scroll;
    padding-left: 0;
  }

  &.mod-blur {
    filter: blur(5px);
    transition: 0.4s filter linear;
  }

  &-burger-menu {
    display: none;
  }

  &-back-button {
    appearance: none;
    background-color: transparent;
    border: none;
    margin-left: -$AppPaddingPx;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: #{$AppPadding / 2}px $AppPaddingPx;
    padding-left: #{$AppPadding / 2}px;
    box-sizing: border-box;
    position: absolute;
    color: $MainCyan;
    top: $AppPaddingPx;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: ' ';
      width: 20px;
      height: 20px;
      mask: url('/assets/icons/chevron-left.svg') no-repeat center;
      mask-size: contain;
      background-color: $MainCyan;
    }
  }
}

@include desktopAndTablet {
  .MainLayout {
    &.mod-blur {
      filter: unset;
    }

    &-wrapper {
      display: flex;
      flex: 1;
      height: 100vh;
      position: unset;
    }

    &-burger-menu {
      display: block;
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      background-color: $white;
      width: 48px;

      &-wrapper {
        cursor: pointer;
      }
    }

    &-main {
      flex: 1;

      overflow-y: scroll;
      height: 100%;
    }
  }
}

@include desktop {
  .MainLayout {
    &-main {
      padding-left: 0;
    }
  }
}
