@import '../../styles/AppDesign.scss';

.InformationCards {
  &-section {
    margin: 16px 0px;

    &-title {
      font-size: 16px;
      margin-top: 0px;
    }

    &-row {
      display: flex;
      padding: 6px 0px;

      &-label {
        min-width: 100px;
        max-width: 100px;
        font-size: 14px;
        color: #8e8e8e;
        // white-space: nowrap;
        line-height: 1.5;
        padding-right: 12px;
      }

      &-value {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        .html-content {
          p {
            &:first-of-type {
              margin-top: 0;
            }
          }
        }

        a {
          color: $MainCyan;
        }
      }
    }
  }
}
