@import '../../styles/AppDesign.scss';

.ListFilterTabs {
  width: 100%;
  margin: 30px 0px 10px;
  display: flex;
  gap: 14px;

  &-item {
    appearance: none;
    background: transparent;
    flex: 1;
    height: 30px;
    border: solid 1px $MainCyan;
    border-radius: 15px;
    color: $MainCyan;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    &.mod-active {
      background-color: $MainCyan;
      color: #fff;
      cursor: default;
    }
  }
}
