@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';
@import '../../../../styles/animations.scss';

.TopToolbar {
  width: 1;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  margin: auto;

  &-lang-switch-container {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  &-back-button {
    display: flex;
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    color: $MainCyan;
    padding-left: 0;

    &:before {
      display: inline-block;
      content: ' ';
      width: 20px;
      height: 20px;
      mask: url('/assets/icons/chevron-left.svg') no-repeat center;
      mask-size: contain;
      background-color: $MainCyan;
    }
  }
}

@include tablet {
  .TopToolbar {
    height: 52px;
    padding-left: 48px;
  }
}

@include mobile {
  .TopToolbar {
    height: 52px;
    padding-left: 48px;
  }
}
