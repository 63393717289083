@import '../../../../styles/AppDesign.scss';

.IconCardButton {
  position: relative;
  cursor: pointer;
  padding: 0px;
  box-sizing: border-box;

  &.mod-disabled {
    pointer-events: none;
    > .Card {
      opacity: 0.5;
    }
  }

  &.mod-children {
    .Card {
      justify-content: center;
      padding: 0;

      .IconCardButton {
        &-icon {
          margin-bottom: 1rem;
        }

        &-title {
          font-size: 1.1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &-children {
    color: $TextDarkerGray;
    font-size: 0.95rem;
    text-align: center;

    p {
      margin: 0;
      line-height: 1.8;
    }
  }

  & .Card {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    margin: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
  }

  &-backgroundImage {
    min-height: 100px;
    background-size: cover;
    background-position: right;
  }

  &-icon {
    display: block;
    width: 34px;
    height: 36px;
    background-color: $MainCyan;
    margin-bottom: 6px;
  }

  &-title {
    z-index: 1;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }

  &-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 27px;
    height: 27px;
    background-color: #fa6b6b;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    right: 0px;
    top: 0px;
    transform: translate(40%, -40%);
  }
}
