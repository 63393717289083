@import '../../styles/media-queries.scss';

@include desktop {
  .Login {
    .VisitorLayout {
      &-hint-button {
        margin-bottom: auto;
      }

      &-container {
        justify-content: center;
        height: 100%;
      }
    }
  }
}
