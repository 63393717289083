@import '~styles/AppDesign.scss';

.Home {
  &.mod-desktop {
    .Home {
      &-header-container {
        font-size: 2.5rem;

        p {
          margin: 0.5rem 0;
          font-size: 1rem;
          color: #bebebe;
        }
      }

      &-cards-row {
        display: flex;
        margin: 1rem 0;
        justify-content: space-between;

        &-column {
          display: flex;
          justify-content: space-between;
          width: calc(50% - 0.5rem);

          .IconCardButton {
            margin: 0;
          }

          > .Card {
            margin: 0;
          }
        }
      }

      &-notifications-container {
        margin: 0;
        width: calc(100% - #{$AppPadding * 2}px);
      }
    }
  }
}
