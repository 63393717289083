@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.NotificationsPreview {
  &-title {
    font-size: 16px;
    color: $MainCyan;
    margin-bottom: 8px;
  }

  &-empty {
    color: $TextGray;
  }

  &-list {
    width: 100%;

    &-item {
      width: 100%;
      border-top: solid 1px #f4f4f4;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;

      &-icon {
        display: block;
        min-width: 22px;
        width: 22px;
        height: 22px;
        background-color: $MainCyan;
        margin-right: 10px;
      }

      &-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        color: #8e8e8e;
      }
    }
  }
}

@include desktopAndTablet {
  .NotificationsPreview {
    &-title {
      margin-top: 0;
    }
  }
}
