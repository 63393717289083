@import '~styles/AppDesign.scss';

.FormDetailsHeader {
  width: 100%;

  &-id {
    font-size: 13px;
    color: $TextGray;
    user-select: all;
  }

  &-title {
    font-size: 22px;
    line-height: 1.5;
  }

  &-title-loading-skeleton {
    margin: 12px 0px;
    height: 40px;
  }
}
