@import '../../styles/AppDesign.scss';

.ItemList {
  position: relative;

  &-list {
    &-loading-skeleton-container {
      margin: 18px 0px;
      border-radius: 10px;
      max-height: 84px;
      height: 84px;
      width: 100%;

      .react-loading-skeleton {
        height: 100%;
        border-radius: 10px;
        --base-color: #e6eaed;
      }
    }

    &-empty-container {
      padding: 80px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 13px;
      color: #8e8e8e;
    }

    &-content-container {
      padding: 16px;
    }

    &-item {
      max-height: 84px;
      height: 84px;
      padding: 0;
      position: relative;
      margin: 18px 0px;
      cursor: pointer;

      &.mod-disabled {
        cursor: default !important;
        background-color: #fafafa;
        opacity: 0.8;
      }

      &-title {
        font-size: 15px;
        font-weight: 700;
        padding-bottom: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-metadata {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        &-status {
          font-size: 11px;

          &.mod-true {
            color: $NeonGreen;
          }
          &.mod-false {
            color: $CoralRed;
          }
        }

        &-createdAt {
          font-size: 13px;
          color: #8e8e8e;
        }
      }

      &-badge {
        position: absolute;
        right: -8px;
        top: -8px;
        width: 19px;
        height: 19px;
        border-radius: 100%;
        background-color: $CoralRed;
      }
    }
  }

  &-loadingOverlay {
    position: absolute;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    top: -8px;
    left: -8px;
    background-color: #ffffff55;
    background-blend-mode: screen;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      max-height: 100px;
    }
  }
}
