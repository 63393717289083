@import '../../styles/AppDesign.scss';

.OTPInput {
  display: flex;
  justify-content: center;
  width: 100%;

  .InputTextField {
    width: initial;
    max-width: 50px;
    flex: 1;
    margin: 0 8px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }

    input {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      padding: 0 4px;
      height: 55px;
    }
  }
}
