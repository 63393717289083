@import '../../styles/AppDesign.scss';

.Appointments {
  &-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-create-appointment-cta {
    width: 100%;
    margin-top: 1rem;
    max-height: 45px;
  }

  &-item-status {
    &-badge {
      background-color: $NeonGreen !important;
    }

    &-true {
      color: $LightGray !important;
    }

    &-false {
      color: $NeonGreen !important;
    }
  }
}
