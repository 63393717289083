@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

$TransitionSpeed: 0.4s;

.MainNavigation {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $white;
  height: 100%;
  border-right: $BorderGray;
  transition: all $TransitionSpeed ease-in-out;
  width: 20vw;
  min-width: 260px;
  max-width: 260px;
  overflow-x: hidden;

  &.mod-close {
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 0;
    min-width: 0;
    width: 0;

    .MainNavigation {
      &-link {
        visibility: hidden;
      }

      &-burger-menu {
        position: fixed;
        margin-left: 0;
        width: 48px;
        background-color: rgba($white, 0.95);
        border-bottom-right-radius: 10px;
      }
    }
    .ApplicationVersion {
      visibility: hidden;
    }
  }

  &-burger-menu {
    min-height: 48px;
    padding-top: 0.1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
  }

  &-container {
    padding: 10% 0;
    margin-bottom: auto;
    flex: 1;
  }

  &-link {
    width: 95%;
    font-weight: 600;
    font-size: 16px;
    color: $TextGray;
    display: flex;
    align-items: center;
    height: 52px;
    margin-bottom: 8px;

    &:hover,
    &.mod-active {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      background-color: rgba($MainCyan, 0.1);
      color: $MainCyan;

      .MainNavigation-link-icon {
        background-color: $MainCyan;
      }
    }

    &-icon {
      margin-left: 12%;
      margin-right: 15px;
      background-color: $TextGray;
      min-width: 28px;
      width: 28px;
      height: 28px;
    }

    &-btn-dropdown {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: none;
      margin-left: auto;
      margin-right: 8%;
      // mask: url('/assets/icons/dropdown_arrow.svg') no-repeat center;
      background-color: $TextGray;
      mask-size: contain;
      height: 100%;
      cursor: pointer;

      &.mod-active {
        background-color: $MainCyan;
      }
    }
  }

  .ApplicationVersion {
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
  }
}

@include tablet {
  .MainNavigation {
    position: absolute;
    left: 0;
    z-index: 10;

    &.mod-open {
      box-shadow: 50px 0px 50px 21px rgba(0, 0, 0, 0.05);
    }
  }
}

@include mobile {
  .MainNavigation {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 10;

    &.mod-open {
      background-color: rgba($white, 0.75);
    }

    &-container {
      padding: 5% 0;
    }

    &-link {
      width: 95%;
      &-icon {
        margin-left: 10%;
      }
    }
  }
}
