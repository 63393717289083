@import '../../styles/AppDesign.scss';

.FormsAndDocuments {
  &-item {
    &-unsupported {
      font-size: 11px;
      color: $TextGray !important;
    }

    &-in-review {
      color: $MainCyan !important;
    }

    &-pending-admin-signing {
      color: $NeonGreen !important;
    }
  }
}
