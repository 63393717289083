@import '../../styles/AppDesign.scss';

.HeaderCard {
  position: relative;
  padding-top: 34px;

  &.mod-hide-logo {
    padding-top: 16px;
    .HeaderCard {
      &-title {
        margin-top: 1rem;
      }
      &-icon {
        display: none;
      }
    }
  }

  &-icon {
    display: block;
    width: 6rem;
    height: 6rem;
    background: url('/assets/icons/ssp_logo.svg') no-repeat center;
    background-size: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0px;
    left: 50%;
  }

  &-title {
    font-size: 28px;
    color: $TextColor;
    margin-bottom: 0px;
    font-weight: 600;

    & > span,
    & > b {
      font-size: 38px;
    }

    & > span {
      font-weight: 300;
    }
  }

  &-description {
    font-size: 12px;
    color: #bebebe;

    &.mod-large {
      font-size: 18px;
      margin-top: 12px;
    }
  }
}
