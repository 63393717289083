@import '../../styles/AppDesign.scss';

.GoogleMaps {
  position: relative;

  @mixin containerStyle {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    border: 1px solid $NavGray;
  }

  &.mod-loaded {
    cursor: pointer !important;
  }

  &-iframe {
    @include containerStyle;
    pointer-events: none;
  }

  &-loading {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;

    .react-loading-skeleton {
      @include containerStyle;
    }
  }
}
